import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import OrderForm from "./screens/pph-2023/order-form"
import HowToUse from "./screens/pph-2023/how-to-use"
import StarterPack from "./screens/pph-2023/starter-pack"
import Cta from "./screens/pph-2023/cta"
const FORM_ID = process.env.GATSBY_FORM_STI_PPH_2023

const SexualHealthTest = () => {
  return (
    <React.Fragment>
      <OrderForm
        wordpressFormID={FORM_ID}
        thankYouPage="pph-2023-thank-you"
      />
      <StarterPack />
      <HowToUse />
      <Cta />
    </React.Fragment>
  )
}
export default SexualHealthTest

export const Head = () => (
  <Seo
	  title="Planned Parenthood 2023"
	  description="Visby Medical's PCR Sexual Health Test detects chlamydia, gonorrhea, and trichomoniasis in under 30 minutes."
	  image="/meta/sexual-health-test.jpg"
/>
)
